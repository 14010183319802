.baggage-animation-renderer{
    width: 100%;
    height: 100%;
}

.baggage-animation-container{
    height: 90%;
    width: 90%;
    cursor: grab;
}

.baggage-animation-container:active{
    cursor: grabbing;
}
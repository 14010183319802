body {
  margin: 0;
  font-family: "Proxima Nova", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  user-select: none;
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
body {
  -ms-overflow-style: none;
}

@font-face {
  font-family: "Proxima Nova";
  src: url('./ProximaNova-Regular.ttf') format("truetype");
}

@font-face {
  font-family: "Proxima Nova Bold";
  src: url('./ProximaNova-Bold.ttf') format("truetype");
}

p{
  margin: 0;
  padding: 0;
}
